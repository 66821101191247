import JSON from 'SOURCE_ROOT/shared_variables.json';

const breakpoints: {[key: string]: MediaQueryList} = Object.freeze(
  Object.keys(JSON.breakpoints).reduce(
    (acc, cur) => ({
      ...acc,
      [cur]: window.matchMedia(
        (({type, value}) => `(${type}:${value})`)(JSON.breakpoints[cur])
      ),
    }),
    {}
  )
);

export default breakpoints;
