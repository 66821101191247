const moduleInitializer = (moduleSelector: string, initCallback: Function): void => {
  window.addEventListener('DOMContentLoaded', () => {
    const matchedElements = document.querySelectorAll(moduleSelector);

    if (matchedElements !== null) {
      matchedElements.forEach((elemItem) => {
        initCallback(elemItem);
      });
    }
  });
}

export default moduleInitializer;